<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 355px">
        <field
          name="codigo"
          widget="char"
          searchable
          label="Nº Reparación"
          width="85px"
          style="top: 3px; left: 9px"
          :readonly="mode == 'edit'"
          inputalign="center"
          fontweight="bold"
        />
        <field
          name="fecha"
          type="date"
          default="today"
          label="Fecha de reparación"
          required
          width="110px"
          style="top: 3px; left: 100px"
          searchable
          fontweight="bold"
        />
        <field
          class="reparacion-color"
          name="estado"
          widget="radio"
          :options="{
            Pendiente: 'pendiente',
            'Enviada Taller': 'enviada',
            Finalizada: 'finalizada',
            'Entregada Cliente': 'entregada',
          }"
          style="top: 9px; left: 260px"
        />
        <field
          name="cliente_id"
          widget="m2o"
          label="Cliente"
          labelProp="nombre_comercial"
          required
          width="265px"
          style="top: 45px; left: 9px"
          searchable
          imgProp="imagen.imagen"
          :fields="[
            'iva',
            're',
            'nombre_fiscal',
            'nif_cif',
            'direccion_fiscal',
            'direccion_fiscal1',
            'cp_fiscal',
            'poblacion_fiscal',
            'provincia_fiscal_id',
            'pais_fiscal_id',
            'telefono_fiscal',
            'movil_fiscal',
            'email_fiscal',
            'web_fiscal',
          ]"
        />
        <field
          name="proveedor_id"
          widget="m2o"
          label="Taller"
          labelProp="nombre_comercial"
          :filter="['realiza_reparaciones', '=', 1]"
          required
          width="265px"
          style="top: 45px; left: 330px"
          searchable
          imgProp="imagen.imagen"
        />
        <r-tabs
          ref="main-tabs"
          style="top: 85px; left: 0px; height: 265px"
          :wt="formData.wt"
          :buttons="[
            'Datos Cliente',
            'Reparación',
            'Historial',
            'Imagen',
            'Notas',
          ]"
        >
          <div class="tab">
            <field
              :readonly="true"
              name="cliente_id.nombre_fiscal"
              widget="input"
              label="Nombre"
              help="Razon Social"
              inline-label
              placeholder="Nombre Fiscal"
              searchable
              width="280px"
              style="top: 10px; right: 300px"
              required
            />
            <field
              :readonly="true"
              name="cliente_id.nif_cif"
              widget="input"
              label="NIF/CIF"
              inline-label
              help="NIF/CIF del Cliente"
              required
              placeholder="NIF/CIF"
              searchable
              fontweight="bold"
              width="90px"
              style="top: 40px; right: 489px"
            />
            <field
              :readonly="true"
              name="cliente_id.direccion_fiscal"
              widget="input"
              label="Dirección"
              help="Dirección Fiscal"
              inline-label
              placeholder="Dirección fiscal"
              searchable
              width="340px"
              style="top: 70px; right: 238px"
              @change="onChangeCopy('direccion_fiscal', 'direccion_comercial')"
            />
            <field
              :readonly="true"
              name="cliente_id.direccion_fiscal1"
              widget="input"
              label
              help="Dirección Fiscal"
              inline-label
              searchable
              width="340px"
              style="top: 100px; right: 238px"
              @change="
                onChangeCopy('direccion_fiscal1', 'direccion_comercial1')
              "
            />
            <field
              :readonly="true"
              name="cliente_id.cp_fiscal"
              inputalign="center"
              max="8"
              widget="input"
              help="Código Postal del Cliente"
              label="C.P."
              inline-label
              placeholder="CP"
              searchable
              width="64px"
              style="top: 130px; right: 513px"
              @change="onChangeCP('fiscal')"
            />
            <field
              :readonly="true"
              name="cliente_id.poblacion_fiscal"
              widget="input"
              label
              inline-label
              placeholder="Poblacion del Cliente"
              searchable
              width="275px"
              style="top: 130px; right: 238px"
              @change="onChangeCopy('poblacion_fiscal', 'poblacion_comercial')"
            />
            <field
              :readonly="true"
              name="cliente_id.provincia_fiscal_id"
              dbAdapter="provincia"
              widget="m2o"
              label="Provincia"
              help="Provincia del Cliente"
              inline-label
              placeholder="Provincia del Cliente"
              searchable
              width="260px"
              style="top: 160px; right: 317px"
              :fields="[
                'nombre',
                {
                  name: 'pais_id',
                  fields: [
                    'nombre',
                    'iva',
                    're',
                    'nacionalidad',
                    { name: 'moneda_id', fields: ['nombre'] },
                  ],
                },
              ]"
              @select="
                onChangeCopy('provincia_fiscal_id', 'provincia_comercial_id')
              "
            />
            <field
              :readonly="true"
              name="cliente_id.pais_fiscal_id"
              dbAdapter="pais"
              widget="m2o"
              label="Pais"
              placeholder="Pais del Cliente"
              help="Pais del Cliente"
              inline-label
              searchable
              width="260px"
              style="top: 190px; right: 317px"
              :fields="[
                'iva',
                're',
                'nacionalidad',
                { name: 'moneda_id', fields: ['nombre'] },
              ]"
            />

            <field
              :readonly="true"
              name="cliente_id.telefono_fiscal"
              widget="input"
              label="Teléfono"
              help="Teléfono del Cliente"
              inline-label
              placeholder="Teléfono"
              searchable
              width="120px"
              style="top: 40px; right: 63px"
            />
            <field
              :readonly="true"
              name="cliente_id.movil_fiscal"
              widget="input"
              label="Móvil"
              help="Teléfono Móvil del Cliente"
              inline-label
              placeholder="Móvil"
              searchable
              width="120px"
              style="top: 70px; right: 63px"
            />
            <field
              :readonly="true"
              name="cliente_id.email_fiscal"
              widget="input"
              help="E-Mail del cliente"
              label="E-Mail"
              inline-label
              placeholder="e-mail"
              searchable
              width="180px"
              :class="{
                wrong:
                  formData.email_fiscal &&
                  !$utils.validation.checkEmail(formData.email_fiscal),
              }"
              style="top: 100px; right: 3px"
            />
            <field
              :readonly="true"
              name="cliente_id.web_fiscal"
              widget="input"
              help="Web del Cliente"
              label="Web"
              inline-label
              placeholder="Web"
              searchable
              width="180px"
              style="top: 130px; right: 3px"
            />
          </div>
          <div class="tab">
            <field
              ref="dias"
              name="dias"
              label="Días/Fecha Prevista de Entrega"
              searchable
              width="60px"
              style="top: 10px; left: 10px"
              suffix=" días"
              widget="int"
              type="number"
              dec="0"
              @change="onChangeDias"
            />
            <field
              name="fecha_prevista"
              type="date"
              default="today"
              searchable
              width="110px"
              style="top: 24px; left: 80px"
              @change="onChangeFechaPrevista"
            />
            <field
              name="nsobre"
              widget="char"
              label="Nº Sobre Cliente"
              searchable
              width="120px"
              style="top: 10px; left: 200px"
            />
            <field
              name="articulo_id"
              widget="m2o"
              label="Artículo"
              searchable
              width="240px"
              style="top: 10px; left: 400px"
            />
            <fieldset
              style="
                position: relative;
                left: 10px;
                top: 45px;
                width: 632px;
                height: 85px;
              "
            >
              <legend>Descripción de la pieza</legend>
              <field
                name="descripcion_pieza"
                widget="text"
                width="620px"
                style="top: 0px; left: 5px"
              />
              <field
                name="nserie"
                widget="char"
                inline-label
                label="Número de serie"
                width="200px"
                style="top: 41px; left: 5px"
              />
            </fieldset>
            <fieldset
              style="
                position: relative;
                left: 10px;
                top: 50px;
                width: 632px;
                height: 65px;
              "
            >
              <legend>Reparación a realizar</legend>
              <field
                name="descripcion_reparacion"
                widget="text"
                width="620px"
                style="top: 0px; left: 5px"
              />
            </fieldset>
          </div>
          <div class="tab">
            <field
              ref="historial"
              name="historial"
              widget="handsontable"
              :height="152"
              :minRows="6"
              style="top: 10px; left: 4px; width: 648px"
              :columns="[
                {
                  name: 'fecha',
                  header: 'Fecha',
                  type: 'date2',
                  readOnly: true,
                },
                {
                  name: 'estado',
                  header: 'Estado',
                  type: 'select',
                  readOnly: true,
                  options: {
                    pendiente: 'Pendiente',
                    enviada: 'Enviada Taller',
                    finalizada: 'Finalizada',
                    entregada: 'Entregada Cliente',
                  },
                  beforeRenderer: function (td) {
                    td.style.background = 'red!important';
                  },
                },
                { name: 'observaciones', header: 'Observaciones' },
              ]"
            />
            <field
              name="importe_taller"
              widget="float"
              type="number"
              label="Importe Taller"
              width="85px"
              style="top: 175px; left: 10px"
            />
            <field
              name="margen"
              sufix="%"
              widget="float"
              type="number"
              label="Margen"
              width="75px"
              style="top: 175px; left: 100px"
            />
            <field
              name="ivap"
              sufix="%"
              widget="float"
              type="number"
              label="%IVA"
              width="60px"
              style="top: 175px; left: 180px"
              :readonly="true"
            />
            <field
              name="rep"
              sufix="%"
              widget="float"
              type="number"
              label="%RE"
              width="60px"
              style="top: 175px; left: 245px"
              :readonly="true"
            />
            <field
              name="total"
              widget="float"
              type="number"
              label="Total"
              width="85px"
              style="top: 175px; left: 310px"
            />
            <field
              name="rep_sin_recargo"
              widget="checkbox"
              label="Rep. Sin Cargo para el Cliente"
              inline-label
              style="top: 180px; right: 10px"
            />
          </div>
          <div class="tab">
            <fieldset
              style="
                position: relative;
                left: 5px;
                top: 10px;
                width: 288px;
                height: 205px;
                display: inline-block;
              "
            >
              <legend>Antes</legend>
              <field
                name="thumbnail_antes"
                widget="image"
                hq_image="imagen_antes"
                style="width: 175px; height: 175px; top: 0px; left: 10px"
              />
              <field
                name="thumbnail_antes1"
                widget="image"
                hq_image="imagen_antes1"
                style="width: 85px; height: 85px; top: 0px; left: 190px"
              />
              <field
                name="thumbnail_antes2"
                widget="image"
                hq_image="imagen_antes2"
                style="width: 85px; height: 85px; top: 90px; left: 190px"
              />
            </fieldset>
            <fieldset
              style="
                position: relative;
                left: 30px;
                top: 10px;
                width: 288px;
                height: 205px;
                display: inline-block;
              "
            >
              <legend>Después</legend>
              <field
                name="thumbnail_despues"
                widget="image"
                hq_image="imagen_despues"
                style="width: 175px; height: 175px; top: 0px; left: 10px"
              />
              <field
                name="thumbnail_despues1"
                widget="image"
                hq_image="imagen_despues1"
                style="width: 85px; height: 85px; top: 0px; left: 190px"
              />
              <field
                name="thumbnail_despues2"
                widget="image"
                hq_image="imagen_despues2"
                style="width: 85px; height: 85px; top: 90px; left: 190px"
              />
            </fieldset>
          </div>
          <div class="tab">
            <field
              name="notas"
              widget="text"
              placeholder="Escriba aquí..."
              width="634px"
              height="204px"
              style="top: 10px; left: 12px"
            />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>Listado</div>
      <div class="my-c ontent" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Reparaciones",
      dbAdapter: "reparacion",
      primary: "codigo",
      defaultData: {
        estado: "pendiente",
        fecha: new Date().yyyymmdd(),
      },
      sequence: {
        name: "reparacion",
      },
    };
  },
  methods: {
    onChangeDias() {
      var self = this;
      var d = new Date();
      d.setDate(d.getDate() + parseInt(self.formData.dias || 0));
      var pd =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2);
      self.$set(self.formData, "fecha_prevista", pd);
    },
    onChangeFechaPrevista() {
      var self = this;
      var date1 = new Date();
      var date2 = new Date(self.formData.fecha_prevista);
      var dif = Math.ceil(
        (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
      );
      self.$set(self.formData, "dias", dif);
    },
  },
  mounted() {
    var self = this;

    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          if (
            self.mode != "edit" &&
            self.mode != "new" &&
            self.mode != "search"
          )
            return;
          self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
          self.$emit("change", "wt", "edit");
          if (self.mode == "search") self.setFilter(self.getFilter());
        }
      }
    );

    self.$on("change", function (prop, source) {
      let arrProps = ["cliente_id", "wt"];
      if (this.mode != "new" && this.mode != "edit") return;
      if (!arrProps.includes(prop)) return;
      self.$set(
        self.formData,
        "ivap",
        parseFloat(self.formData.cliente_id.iva || 0) *
          !parseInt(self.formData.wt || 0)
      );
      self.$emit("change", "ivap", "auto");
    });

    self.$on("change", function (prop, source) {
      let arrProps = ["cliente_id", "rep_sin_recargo", "wt"];
      if (this.mode != "new" && this.mode != "edit") return;
      if (!arrProps.includes(prop)) return;
      self.$set(
        self.formData,
        "rep",
        parseFloat(self.formData.cliente_id.re || 0) *
          !parseInt(self.formData.wt || 0) *
          !parseInt(self.formData.rep_sin_recargo || 0)
      );
      self.$emit("change", "rep", "auto");
    });

    self.$on("change", function (prop, source) {
      let arrProps = ["importe_taller", "margen", "ivap", "rep"];
      if (this.mode != "new" && this.mode != "edit") return;
      if (!arrProps.includes(prop)) return;
      self.$set(
        self.formData,
        "total",
        parseFloat(self.formData.importe_taller || 0) *
          (1 + parseFloat(self.formData.margen || 0) / 100) *
          (1 + self.formData.ivap / 100) *
          (1 + self.formData.rep / 100)
      );
    });

    self.$on("change", function (prop, source) {
      let arrProps = ["total"];
      if (this.mode != "new" && this.mode != "edit") return;
      if (!arrProps.includes(prop)) return;
      let margen =
        (self.formData.total /
          (self.formData.importe_taller *
            (1 + self.formData.ivap / 100) *
            (1 + self.formData.rep / 100)) -
          1) *
        100;
      if (!isFinite(margen)) margen = 0;
      self.$set(self.formData, "margen");
    });
  },
};
</script>
<style>
.reparacion-color.field[name="estado"] .buttonset .button.active {
  background-color: #ffffbb;
  color: black;
}
.reparacion-color.field[name="estado"] input[value="enviada"] + .button.active {
  background-color: #ffddaa;
  color: black;
}
.reparacion-color.field[name="estado"] input[value="finalizada"] + .button.active {
  background-color: #aadaff;
  color: black;
}
.reparacion-color.field[name="estado"] input[value="entregada"] + .button.active {
  background-color: #cec;
  color: black;
}
</style>